<script>
import feather from 'feather-icons';
import Button from './reusable/Button.vue';
import emailjs from 'emailjs-com';
export default {
	props: ['showModal', 'modal'],
	components: { Button },
	data() {
		return {
			name: '',
			email: '',
			message: ''
		};
	},
	mounted() {
		feather.replace();
	},
	updated() {
		feather.replace();
	},
	methods: {
		setSelected(value) {
			console.log(value)
		},
		resetForm() {
			this.name = '';
			this.email = '';
			this.message = '';
		},
		onSubmit() {
			try {
				emailjs.sendForm('service_f6mdnt4', 'template_6o97yls', this.$refs.form, 'HlBz714bj6geZOmdv', {
					name: this.name,
					email: this.email,
					message: this.message
				});

				this.showModal();
				this.resetForm();
				this.$toast.show('Thanks! I\'ll be in touch soon.', {
					type: 'success',
					position: 'top'
				});
			} catch (e) {
				this.$toast.show('Something went wrong! Please try again', {
					type: 'error',
					position: top
				});

				throw new Error(e);
			}
		}
	},
};
</script>

<template>
	<transition name="fade">
		<div v-show="modal" class="font-general-regular fixed inset-0 z-30">
			<!-- Modal body background as backdrop -->
			<div
				v-show="modal"
				@click="showModal()"
				class="bg-filter bg-black bg-opacity-50 fixed inset-0 w-full h-full z-20"
			></div>
			<!-- Modal content -->
			<main
				class="flex flex-col items-center justify-center h-full w-full"
			>
				<transition name="fade-up-down">
					<div
						v-show="modal"
						class="modal-wrapper flex items-center z-30"
					>
						<div
							class="modal max-w-md mx-5 xl:max-w-xl lg:max-w-xl md:max-w-xl bg-secondary-light dark:bg-primary-dark max-h-screen shadow-lg flex-row rounded-lg relative"
						>
							<div
								class="modal-header flex justify-between gap-10 p-5 border-b border-ternary-light dark:border-ternary-dark"
							>
								<h5
									class=" text-primary-dark dark:text-primary-light text-xl"
								>
									Stop dreaming, start testing
								</h5>
								<button
									class="px-4 text-primary-dark dark:text-primary-light"
									@click="showModal()"
								>
									<i data-feather="x"></i>
								</button>
							</div>
							<div class="modal-body p-5 w-full h-full">
								<form class="max-w-xl m-4 text-left" ref="form" @submit.prevent="onSubmit">
									<div class="mt-0">
										<input
											class="w-full px-5 py-2 border-1 border-gray-200 dark:border-secondary-dark rounded-md text-md bg-secondary-light dark:bg-ternary-dark text-primary-dark dark:text-ternary-light"
											id="name"
											v-model="name"
											name="name"
											type="text"
											required=""
											placeholder="Name"
											aria-label="Name"
										/>
									</div>
									<div class="mt-6">
										<input
											class="w-full px-5 py-2 border-1 border-gray-200 dark:border-secondary-dark rounded-md text-md bg-secondary-light dark:bg-ternary-dark text-primary-dark dark:text-ternary-light"
											id="email"
											v-model="email"
											name="email"
											type="email"
											required=""
											placeholder="Email"
											aria-label="Email"
										/>
									</div>

									<div class="mt-6">
										<textarea
											class="w-full px-5 py-2 border-1 border-gray-200 dark:border-secondary-dark rounded-md text-md bg-secondary-light dark:bg-ternary-dark text-primary-dark dark:text-ternary-light"
											id="message"
											v-model="message"
											name="message"
											cols="14"
											rows="6"
											aria-label="Details"
											required
											placeholder="Describe your project."
										></textarea>
									</div>

									<div class="mt-6 pb-4 sm:pb-1">
										<Button
											title="Request quote"
											class="px-4
											sm:px-6
											py-2
											sm:py-2.5
											text-white
											bg-indigo-500
											hover:bg-indigo-600
											rounded-md
											focus:ring-1 focus:ring-indigo-900 duration-500"
											type="submit"
											aria-label="Submit Request"
										/>
									</div>
								</form>
							</div>
						</div>
					</div>
				</transition>
			</main>
		</div>
	</transition>
</template>

<style scoped>
.modal-body {
	max-height: 500px;
}
.bg-gray-800-opacity {
	background-color: #2d374850;
}

</style>

<script>
import feather from 'feather-icons';

export default {
	name: 'Home',
	data: () => {
		return {
			theme: ''
		};
	},
	created() {
		this.theme = localStorage.getItem('theme') || 'light';
	},
	mounted() {
		feather.replace();
		this.theme = localStorage.getItem('theme') || 'light';
	},
	updated() {
		feather.replace();
	},
	computed: {
		yearsOfExperience() {
			return `${new Date().getFullYear() - 2015}`
		}
	},
};
</script>

<template>
	<section
		class="flex flex-col justify-center items-center mt-40 pt-40"
	>
		<!-- Banner left contents -->
		<div class="w-full md:w-1/2">
			<h1 data-cy="main-headline"
				class="font-general-semibold text-4xl md:text-4xl xl:text-4xl text-center mt-20 text-ternary-dark dark:text-primary-light uppercase"
			>
				Hi. I'm Bojan,
			</h1>
			<p
				data-cy="main-description"
				class="font-general-medium text-md sm:text-2xl xl:text-2xl text-center pt-5 leading-none text-gray-400"
			>
				a passionate QA engineer, educator and leader with over {{ yearsOfExperience }} years of experience.
			</p>
			<div class="flex justify-center sm:block">
				<a
					download="Resume-Bojan-Dimitrovski.pdf"
					href="/files/Resume-Bojan-Dimitrovski.pdf"
					class="flex justify-center w-full w-26 mt-12 mb-6 sm:mb-0 text-lg border border-indigo-200 dark:border-ternary-dark py-2.5 sm:py-3 shadow-lg rounded-lg bg-indigo-50 focus:ring-1 focus:ring-indigo-900 hover:bg-indigo-500 text-gray-500 hover:text-white duration-500"
					aria-label="Download Resume"
				>
					<span
						data-cy="download-cv"
						class="text-sm sm:text-lg font-general-medium"
						>Download CV</span
					></a
				>
			</div>
		</div>

	</section>
</template>

<style scoped>

body {
    font-family: "Inter", sans-serif;
  }

  .background-animate {
	width: 100%;
   height: 1px;

    background-size: 400%;

    -webkit-animation: AnimationName 10s ease infinite;
    -moz-animation: AnimationName 10s ease infinite;
    animation: AnimationName 10s ease infinite;
  }

  @keyframes AnimationName {
    0%,
    100% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
  }
</style>
